import { Component, inject } from '@angular/core';
import { Auth, authState, GoogleAuthProvider, signInWithPopup, User } from '@angular/fire/auth';
import { EMPTY, Observable } from 'rxjs';
import { AsyncPipe } from '@angular/common';
import { MatToolbar } from '@angular/material/toolbar';
import { MatButton } from '@angular/material/button';
import { RouterLink } from '@angular/router';
import { MatMenuTrigger, MatMenu, MatMenuItem } from '@angular/material/menu';
import { MatIcon } from '@angular/material/icon';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['../common-styles.css', './header.component.css'],
  imports: [
    MatToolbar,
    MatButton,
    RouterLink,
    MatMenuTrigger,
    MatIcon,
    MatMenu,
    MatMenuItem,
    AsyncPipe,
  ],
})
export class HeaderComponent {
  auth = inject(Auth);

  public readonly user: Observable<User | null> = EMPTY;

  constructor() {
    this.user = authState(this.auth);
  }

  login() {
    signInWithPopup(this.auth, new GoogleAuthProvider());
  }
  logout() {
    this.auth.signOut();
  }
}
